<template>
  <ListItem :condensed="true" :actions="actions">
    <div :class="containerClasses" class="w-full">
      <div class="flex items-center w-full">
        <ThreeWayApproval
          v-if="showApprovalBox"
          :item="item"
          :approval="item.approval"
          :forceHide="item.invoice_id"
          :hideApproved="!!(item.invoice_id || item.invoice_status)"
          user="client"
        />
        <p class="mb-0">
          {{ $f.truncate(item.cost_type_name || item.item_name || item.cost_item_name, 40) }}
        </p>
      </div>
      <div
        class="flex items-center justify-center last:justify-end w-full"
        v-if="item.asAssemblyPath"
      >
        <p class="mb-0">{{ $f.truncate(itemPath, 40) }}</p>
      </div>
      <div class="flex items-center justify-center last:justify-end w-full" v-if="showStartDate">
        <p class="mb-0">{{ formatDate(item.start_date) }}</p>
      </div>
      <div class="flex items-center justify-center last:justify-end w-full" v-if="showEndDate">
        <p class="mb-0">{{ formatDate(item.end_date) }}</p>
      </div>
      <div
        class="flex items-center justify-center last:justify-end"
        v-if="settings.displayItemizedPricing"
      >
        <p class="mb-0">{{ $f.currency(item.item_gross) }}</p>
      </div>
      <div class="flex items-center justify-center" v-if="item.invoice_status">
        <Chip
          :pt="{ root: '!rounded' }"
          :ptOptions="{ mergeProps: true }"
          :class="billingStatusClasses"
          :label="billingMessage"
        ></Chip>
      </div>
    </div>
    <template #right>
      <div
        class="mx-4 min-w-32 flex justify-end"
        @click="() => openInvoice(item.invoice_id)"
        v-if="item.invoice_id && item.invoice_id !== 1"
      >
        <a
          class="text-blue-print-300 cursor-pointer hover:text-blue-print-800 transition-all text-nowrap"
        >
          View receipt
        </a>
      </div>
    </template>
    <template v-if="showFooter" #footer>
      <slot name="footer" />
    </template>
  </ListItem>
</template>

<script setup>
import { computed, toRef, useSlots, defineProps } from 'vue'
import moment from 'moment'
import Chip from 'primevue/chip'
import useInvoice from '../../composables/Invoice'
import useApproval from '@/components/composables/Approval'
import usePayment from '@/components/composables/Payment'
import useTranslate from '@/components/composables/Translation'
import ListItem from './ListItem.vue'
import ThreeWayApproval from '@/components/progress/approval/ThreeWayApproval.vue'

const props = defineProps({
  item: {
    required: true
  },
  showStartDate: {
    type: Boolean,
    default: false
  },
  showEndDate: {
    type: Boolean,
    default: false
  },
  showApprovalBox: {
    default: true
  }
})

const item = toRef(props, 'item')
const { openInvoice } = useInvoice()
const {
  isApprovedByApprover,
  processAction,
  getStepsToApprove,
  canApprove,
  isFirstStepOfApproval,
  approvalStatuses
} = useApproval()
const { l } = useTranslate()
const { settings } = usePayment()

const handleDecline = () => {
  return processAction(item.value.approval, approvalStatuses.DECLINED)
}

const actions = computed(() => {
  if (!item.value.approval || Object.keys(item.value.approval).length === 0) return null
  if (isApprovedByApprover(item.value.approval)) return null
  if (item.value.invoice_id) return null
  const steps = getStepsToApprove(item.value.approval)
  if (!steps || steps.length === 0) return null
  if (!canApprove(steps[0])) return null
  if (isFirstStepOfApproval(item.value.approval)) return null
  return [
    {
      title: l('Decline'),
      icon: 'ban',
      action: handleDecline
    }
  ]
})

const slots = useSlots()
const showFooter = computed(() => slots.footer && slots.footer().length > 0)

const containerClasses = computed(() => {
  let cols = 1
  if (item.value.asAssemblyPath) cols++
  if (settings.value.displayItemizedPricing) cols++
  if (props.showStartDate) cols++
  if (props.showEndDate) cols++
  if (item.value.invoice_status) cols++
  return `grid grid-cols-${cols} gap-2`
})

const itemPath = computed(() => {
  const path = item.value.asAssemblyPath.slice(Math.max(item.value.asAssemblyPath.length - 2, 0))
  if (path.length === 1) return ''
  return path.join(' → ')
})

const billingStatusClasses = computed(() => {
  if (item.value.invoice_status === 'p') return '!bg-orange-100 !text-orange-900'
  return '!bg-blue-100 !text-blue-900'
})

const billingMessage = computed(() => {
  if (item.value.invoice_status === 'p') return 'Awaiting Payment'
  return 'Processing'
})

const formatDate = (itemDate) => {
  if (!itemDate) return ''
  return moment.unix(itemDate).format('MMM/DD/YYYY')
}
</script>
